import { createContext, useState } from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

//页面
import Login from '@/pages/login';
import Base from '@/pages/base';
import Index from '@/pages';

import Users from '@/pages/users';
import SysConfig from '@/pages/sysconfig';

import Pages from '@/pages/pages';
import Sites from '@/pages/sites';
import Releases from '@/pages/releases';

import SiteReport from '@/pages/site/report';
import SitePages from '@/pages/site/pages';
import SiteUsers from '@/pages/site/users';
import SiteRecharges from '@/pages/site/recharges';
import SiteBuys from '@/pages/site/buys';
import SiteComments from '@/pages/site/comments';
import SiteLikes from '@/pages/site/likes';
import SiteSearchs from '@/pages/site/searchs';
import SiteConfig from '@/pages/site/config';
import SitePayments from '@/pages/site/payments';

//全局状态
export const GlobalContext=createContext(null);

//引入mock数据
if(process.env.NODE_ENV=='development' && true){
	require('@/mock/site/page.js');
	require('@/mock/site/user.js');
	require('@/mock/site/recharge.js');
	require('@/mock/site/buy.js');
	require('@/mock/site/comment.js');
	require('@/mock/site/like.js');
	require('@/mock/site/search.js');
	require('@/mock/site/config.js');
	require('@/mock/site/payment.js');

	require('@/mock/user.js');
	require('@/mock/sysconfig.js');

	require('@/mock/page.js');
	require('@/mock/site.js');
	require('@/mock/upload.js');
	require('@/mock/release.js');
}

export default function App() {
	document.title='森林CMS';

	const [globalData,setGlobalData] = useState({
		'loginUser':{
			'token':localStorage.getItem('loginUserToken',null),
			'name':localStorage.getItem('loginUserName',''),
			'category':localStorage.getItem('loginUserCategory',null)
		},
		'nowSite':{
			'id':localStorage.getItem('nowSiteId',null),
			'apiUrl':localStorage.getItem('nowSiteApiUrl',null),
			'secretKey':localStorage.getItem('nowSiteSecretKey',null),
			'name':localStorage.getItem('nowSiteName','')
		}
	});

	return (
		<ConfigProvider locale={zhCN}>
			<GlobalContext.Provider  value={{globalData,setGlobalData}}>
				<BrowserRouter>
					<Routes>
						<Route path='/login' element={<Login />}></Route>
						<Route path='/' element={<Base />}>
							<Route index element={<Index />}></Route>

							<Route path='pages' element={<Pages />}></Route>
							<Route path='sites' element={<Sites />}></Route>
							<Route path='releases' element={<Releases />}></Route>

							<Route path='users' element={<Users />}></Route>
							<Route path='sysconfig' element={<SysConfig />}></Route>

							<Route path='site/report' element={<SiteReport />}></Route>
							<Route path='site/pages' element={<SitePages />}></Route>
							<Route path='site/users' element={<SiteUsers />}></Route>
							<Route path='site/recharges' element={<SiteRecharges />}></Route>
							<Route path='site/buys' element={<SiteBuys />}></Route>
							<Route path='site/comments' element={<SiteComments />}></Route>
							<Route path='site/likes' element={<SiteLikes />}></Route>
							<Route path='site/searchs' element={<SiteSearchs />}></Route>
							<Route path='site/config' element={<SiteConfig />}></Route>
							<Route path='site/payments' element={<SitePayments />}></Route>
						</Route>
						<Route path='*' element={<h1>404</h1>}></Route>
					</Routes>
				</BrowserRouter>
			</GlobalContext.Provider>
		</ConfigProvider>
	);
}