import { useContext } from "react";
import { Modal, Form, Input, message } from "antd"

import { Ajax } from '@/api/http.js';
import { GlobalContext } from "@/App.js";

export default function ChangePwd(props){
    const {globalData,setGlobalData}=useContext(GlobalContext);
    const [form]=Form.useForm();

    function onFinish(vals){
        Ajax('/user/changepwd','post',vals).then(res=>{

            localStorage.setItem('userToken',res['data']['token']);

            let tmpData={...globalData};
            tmpData['userToken']=res['data']['token'];
            setGlobalData(tmpData);

            message.success('修改成功');
            form.resetFields();
            props.onClose();
        })
    }

    return (
        <Modal
            open={props.visible}
            title='修改我的密码'
            width={450}
            onCancel={props.onClose}
            onOk={form.submit}
            maskClosable={false}
        >
            <Form
                form={form}
                labelCol={{span:5}}
                onFinish={onFinish}
            >
                <Form.Item label='原密码' name='oldPassword' rules={[{required:true}]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item label='新的密码' name='newPassword' rules={[{required:true}]}>
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    )
}