import { useEffect, useState } from "react";

import { Breadcrumb, Button, Form, Input, Table, Space, Popconfirm, DatePicker} from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { SiteAjax } from '@/api/http.js';

export default function SiteComments(){
    const [rangeDate,setRangeDate] = useState(null);
    const [name,setName] = useState(null);
    const [pageId,setPageId] = useState(null);

    const [tableData,setTableData]=useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const [tablePageCurrent,setTablePageCurrent] = useState(1);
    const [tablePageSize,setTablePageSize] = useState(20);
    const [tableTotal,setTableTotal] = useState(0);
    const [tableSelectedRowKeys, setTableSelectedRowKeys] = useState([]);

    useEffect(()=>{
        getTableData(tablePageCurrent,tablePageSize);
    },[])

    //实际获取表格数据
    function getTableData(pageCurrent,pageSize){
        setTableLoading(true);
        setTableSelectedRowKeys([]);

        let startDate=null;
        let endDate=null;
        if(rangeDate){
            startDate=rangeDate[0].format('YYYY-MM-DD');
            endDate=rangeDate[1].format('YYYY-MM-DD');
        }

        SiteAjax('/admin/comment/getlist','get',{
            startDate:startDate,
            endDate:endDate,
            name:name,
            pageId:pageId,
            pageCurrent:pageCurrent,
            pageSize:pageSize
        },false).then(res=>{
            setTablePageCurrent(pageCurrent);
            setTablePageSize(pageSize);
            setTableData(res['data']);
            setTableTotal(res['total']);
            setTableLoading(false);
        }).catch(err=>{
            setTableLoading(false);
        })
    }

    //点击搜索按钮
    function onSearch(){
        getTableData(1,tablePageSize);
    }

    //点击分页或排序
    function onChangeTablePag(pagination, filters, sorter){
        getTableData(pagination['current'],pagination['pageSize']);
    }

    //删除
    function onDels(){
        SiteAjax('/admin/comment/dels','post',{'ids':tableSelectedRowKeys},true,true,true).then(res=>{
            getTableData(tablePageCurrent,tablePageSize);
        })
    }
   
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>当前网站</Breadcrumb.Item>
                <Breadcrumb.Item>评论记录</Breadcrumb.Item>
            </Breadcrumb>
            <Form
                layout="inline"
                style={{margin:'20px 0 20px 0'}}
            >
                <Form.Item>
                    <DatePicker.RangePicker value={rangeDate} onChange={(v)=>setRangeDate(v)} />
                </Form.Item>
                <Form.Item>
                    <Input placeholder="用户名" value={name} onChange={(e)=>setName(e.target.value)}></Input>
                </Form.Item>
                <Form.Item>
                    <Input placeholder="资源id" value={pageId} onChange={(e)=>setPageId(e.target.value)}></Input>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                </Form.Item>
            </Form>
            <Space style={{margin:'0 0 10px 0'}}>
                <Popconfirm
                    title='确定要删除吗？'
                    onConfirm={onDels}
                >
                    <Button type="primary" danger disabled={tableSelectedRowKeys.length<1}><DeleteOutlined />删除</Button>
                </Popconfirm>
            </Space>
            <Table
                rowSelection={{
                    selectedRowKeys:tableSelectedRowKeys,
                    onChange:(newKeys)=>setTableSelectedRowKeys(newKeys)
                }}
                columns={[
                    {
                        title:'id',
                        dataIndex:'id',
                        key:'id'
                    },
                    {
                        title:'资源id',
                        dataIndex:'pageId',
                        key:'pageId'
                    },
                    {
                        title:'评论内容',
                        dataIndex:'content',
                        key:'content',
                        ellipsis:true,
                        width:400
                    },
                    {
                        title:'评论人',
                        dataIndex:'createrName',
                        key:'createrName'
                    },
                    {
                        title:'评论时间',
                        dataIndex:'createdTime',
                        key:'createdTime'
                    }
                ]}
                rowKey='id'
                dataSource={tableData}
                pagination={{
                    current:tablePageCurrent,
                    pageSize:tablePageSize,
                    total:tableTotal,
                    showTotal:(t)=>'合计'+t
                }}
                onChange={onChangeTablePag}
                loading={tableLoading}
            />
        </>
    )
}
