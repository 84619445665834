import { useEffect, useState } from "react";
import { Breadcrumb, Button, Form, Input, message, Row, Col, Typography, Select } from "antd";

import { SiteAjax } from '@/api/http.js';

export default function SiteConfig(){
    const [form]=Form.useForm();

    const [languages,setLanguages] = useState([]);
    const [currencys,setCurrencys] = useState([]);

    useEffect(()=>{
        SiteAjax('/admin/config/get','get').then(res=>{
            setLanguages(res['data']['languages']);
            setCurrencys(res['data']['currencys']);

            form.setFieldsValue({
                'siteName':res['data']['siteName'],
                'language':res['data']['language'],
                'currency':res['data']['currency'],
                
                's3EndpointUrl':res['data']['s3EndpointUrl'],
                's3AccessKey':res['data']['s3AccessKey'],
                's3SecretKey':res['data']['s3SecretKey'],
                's3BucketName':res['data']['s3BucketName'],
                's3RootDomain':res['data']['s3RootDomain'],

                'gold10':res['data']['gold10'],
                'gold100':res['data']['gold100'],
                'gold1000':res['data']['gold1000'],
                'gold10000':res['data']['gold10000'],
                
                'siteAbout':res['data']['siteAbout'],
                'siteHelp':res['data']['siteHelp'],
                'googleAnalyticsCode':res['data']['googleAnalyticsCode']
            })
        })
    },[])

    //提交表单
    function onFinish(vals){
        SiteAjax('/admin/config/edit','post',vals).then(res=>{
            message.success('保存成功');
        })
    }

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>当前网站</Breadcrumb.Item>
                <Breadcrumb.Item>网站设置</Breadcrumb.Item>
            </Breadcrumb>
            <Row style={{width:'900px',margin:'10px 0 0 0'}}>
                <Col flex='auto'><Typography.Title level={3}>网站设置</Typography.Title></Col>
                <Col flex='80px'><Button type="primary" style={{width:'80px',margin:'25px 0 0 0'}} onClick={()=>form.submit()}>保存</Button></Col>
            </Row>
            <Form
                form={form}
                style={{width:'900px',margin:'0',padding:'50px 40px',backgroundColor:'#fff'}}
                onFinish={onFinish}
                labelCol={{span:4}}
            >
                <Form.Item label='网站名称' name='siteName' rules={[{required:true}]}>
                    <Input placeholder="请输入网站名称" style={{width:'500px'}}></Input>
                </Form.Item>
                <Form.Item label='网站语言' name='language' rules={[{required:true}]}>
                    <Select style={{width:'300px'}} options={languages}></Select>
                </Form.Item>
                <Form.Item label='货币单位' name='currency' rules={[{required:true}]}>
                    <Select style={{width:'300px'}} options={currencys}></Select>
                </Form.Item>

                <Form.Item label='s3端点url' name='s3EndpointUrl' rules={[{required:true}]}>
                    <Input />
                </Form.Item>
                <Form.Item label='s3 access key' name='s3AccessKey' rules={[{required:true}]}>
                    <Input></Input>
                </Form.Item>
                <Form.Item label='s3 secret key' name='s3SecretKey' rules={[{required:true}]}>
                    <Input></Input>
                </Form.Item>
                <Form.Item label='s3 存储桶名称' name='s3BucketName' rules={[{required:true}]}>
                    <Input></Input>
                </Form.Item>
                <Form.Item label='s3访问域名' name='s3RootDomain' rules={[{required:true}]}>
                    <Input></Input>
                </Form.Item>

                <Form.Item label='10个金币价格' name='gold10'>
                    <Input placeholder="请输入10个金币的价格" style={{width:'300px'}}></Input>
                </Form.Item>
                <Form.Item label='100个金币价格' name='gold100'>
                    <Input placeholder="请输入100个金币的价格" style={{width:'300px'}}></Input>
                </Form.Item>
                <Form.Item label='1000个金币价格' name='gold1000'>
                    <Input placeholder="请输入1000个金币的价格" style={{width:'300px'}}></Input>
                </Form.Item>
                <Form.Item label='10000个金币价格' name='gold10000'>
                    <Input placeholder="请输入10000个金币的价格" style={{width:'300px'}}></Input>
                </Form.Item>

                <Form.Item label='关于我们' name='siteAbout'>
                    <Input.TextArea rows={10}></Input.TextArea>
                </Form.Item>
                <Form.Item label='使用帮助' name='siteHelp'>
                    <Input.TextArea rows={10}></Input.TextArea>
                </Form.Item>
                <Form.Item label='网站统计代码' name='googleAnalyticsCode'>
                    <Input.TextArea rows={5}></Input.TextArea>
                </Form.Item>
            </Form>
        </>
    )
}