import { useContext,useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Ajax } from '@/api/http.js';
import { GlobalContext } from "@/App.js";

import { Button, Form, Input, Space, Image } from 'antd';

import imgBg from '@/asset/user_bg.png';
import imglogo from '@/asset/logo.png';

const cssAll={
    backgroundImage:'url('+imgBg+')',
    width:'100vw',
    height:'100vh',
    backgroundRepeat:'no-repeat',
    backgroundSize:'100% 100%'
}

const cssForm={
    width:'360px',
    margin:'0 auto',
    backgroundColor:'#fff',
    borderRadius:'4px',
    padding:'30px 30px',
    textAlign:'center'
}

export default function Login(){
    const nav = useNavigate();
    const {globalData,setGlobalData} = useContext(GlobalContext);

    const [form] = Form.useForm();
    const [codeImg,setCodeImg] = useState(null)

    useEffect(()=>{
        onRefreshCode();
    },[])

    //刷新验证码
    function onRefreshCode(){
        Ajax('/user/getcode','get').then(res=>{
            form.setFieldsValue({
                'codeId':res['data']['id']
            })
            setCodeImg(res['data']['img']);
        })
    }

    //提交表单
    const onFinsih=(vals)=>{
        Ajax('/user/login','post',vals).then(res=>{
            localStorage.setItem('loginUserToken',res['data']['token']);
            localStorage.setItem('loginUserName',res['data']['name']);
            localStorage.setItem('loginUserCategory',res['data']['category']);

            let tmpData={...globalData};
            tmpData['loginUser']={
                'token':res['data']['token'],
                'name':res['data']['name'],
                'category':res['data']['category']
            }
            setGlobalData(tmpData);

            nav('/');
        }).catch(err=>{
            console.log(err);
        })
    }
    return (
        <div style={cssAll}>
            <div style={{height:'20vh'}}></div>
            <div style={cssForm}>
                <img src={imglogo} style={{width:'65px'}} />
                <h1>登陆系统</h1>
                <Form
                    form={form}
                    style={{margin:'25px 0 0 0'}}
                    onFinish={onFinsih}
                    autoComplete="off"
                >
                    <Form.Item name='name' rules={[{required:true}]}>
                        <Input placeholder="用户名" />
                    </Form.Item>
                    <Form.Item name='password' rules={[{required:true}]}>
                        <Input.Password placeholder="密码"></Input.Password>
                    </Form.Item>
                    <Form.Item hidden={true} name='codeId'>
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Form.Item noStyle name='code'  rules={[{required:true}]}>
                                <Input placeholder="验证码" style={{width:'210px'}} />
                            </Form.Item>
                            <Image
                                src={codeImg}
                                title='点击刷新' 
                                preview={false}
                                onClick={onRefreshCode}
                                width={140}
                                height={30}
                            />
                        </Space>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block size="large">
                            登陆
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}