import { useEffect, useState } from "react";

import { Breadcrumb, Button, Form, DatePicker, Row, Col, Card,Table} from "antd";

import { SiteAjax } from '@/api/http.js';


export default function SiteReport(){
    const [rangeDate,setRangeDate] = useState(null);
    
    function onSearch(){
        let startDate=null;
        let endDate=null;
        if(rangeDate){
            startDate=rangeDate[0].format('YYYY-MM-DD');
            endDate=rangeDate[1].format('YYYY-MM-DD');
        }
    }

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>当前网站</Breadcrumb.Item>
                <Breadcrumb.Item>统计报表</Breadcrumb.Item>
            </Breadcrumb>
            <Form
                layout="inline"
                style={{margin:'20px 0 20px 0'}}
            >
                <Form.Item>
                    <DatePicker.RangePicker value={rangeDate} onChange={(v)=>setRangeDate(v)} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={onSearch}>查看报表</Button>
                </Form.Item>
            </Form>            
        </>
    )
}