import { useEffect, useState } from "react";
import { Button, Form, Input, Select, Drawer} from "antd";

import { SiteAjax } from '@/api/http.js';

export default function SitePayment(props){
    const [form] = Form.useForm();
    const [title,setTitle] = useState('');

    const nowCategory = Form.useWatch('category',form);

    const [fields,setFields] = useState([]);

    useEffect(()=>{
        if(nowCategory){
            for(let one of props.categorys){
                if(one['name']==nowCategory){
                    setFields(one['fields']);
                    break;
                }
            }
        }else{
            setFields([]);
        }
        
    },[nowCategory])

    useEffect(()=>{
        if(props.id){
            SiteAjax('/admin/payment/get','get',{'id':props.id}).then(res=>{
                setTitle(res['data']['name']);
                form.setFieldsValue({
                    'category':res['data']['category'],
                    'name':res['data']['name'],
                    'config':res['data']['config']
                })
            })
        }else{
            setTitle('新的收款账户');
            form.resetFields();
        }
    },[props.id])

    //保存
    const onFinish=(vals)=>{
        console.log(vals);
        let url='/admin/payment/add';
        if(props.id){
            url='/admin/payment/edit';
            vals['id']=props.id;
        }

        SiteAjax(url,'post',vals,true,true,true).then(res=>{
            if(!props.id){
                form.resetFields();
            }
            props.onClose();
            props.onRefresh();
        })
    }

    return (
        <Drawer
            width={500}
            open={props.visible}
            onClose={props.onClose}
            title={title}
            extra={
                <Button type="primary" onClick={()=>form.submit()}>保存</Button>
            }
        >
            <Form form={form} onFinish={onFinish} labelCol={{span:5}}>
                <Form.Item label='收款类别' name='category' rules={[{required:true}]}>
                    <Select placeholder='收款类别'>
                        {props.categorys.map((one,index)=>(
                            <Select.Option value={one['name']} key={index}>{one['name']}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label='账户名称' name='name' rules={[{required:true}]}>
                    <Input></Input>
                </Form.Item>
                {fields.map((one,index)=>(
                    <Form.Item label={one} name={['config',one]} key={index}>
                        <Input></Input>
                    </Form.Item>
                ))}
            </Form>
        </Drawer>
    )
}