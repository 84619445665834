import { useState, useEffect } from "react";
import { Button, Form, Input, Drawer } from "antd";

import { SiteAjax } from '@/api/http.js';

export default function SitePage(props){
    const [form] = Form.useForm();
    const [title,setTitle] = useState('');

    useEffect(()=>{
        if(props.id){
            SiteAjax('/admin/page/get','get',{'id':props.id}).then(res=>{
                setTitle(res['data']['title']);
                form.setFieldsValue({
                    'title':res['data']['title'],
                    'content':res['data']['content'],
                    'gold':res['data']['gold']
                })
            })
        }else{
            setTitle('新的资源');
            form.resetFields();
        }
    },[props.id])

    //保存
    const onFinish=(vals)=>{
        let url='/admin/page/add';
        if(props.id){
            url='/admin/page/edit';
            vals['id']=props.id;
        }

        SiteAjax(url,'post',vals,true,true,true).then(res=>{
            if(!props.id){
                form.resetFields();
            }
            props.onClose();
            props.onRefresh();
        })
    }

    return (
        <Drawer
            width={800}
            open={props.visible}
            onClose={props.onClose}
            destroyOnClose={true}
            title={title}
            extra={
                <Button type="primary" onClick={()=>form.submit()}>保存</Button>
            }
        >
            <Form form={form} onFinish={onFinish} labelCol={{span:2,offset:0}}>
                <Form.Item label='标题' name='title' rules={[{required:true}]}>
                    <Input></Input>
                </Form.Item>
                <Form.Item label='正文' name='content'>
                    <Input.TextArea rows={20}></Input.TextArea>
                </Form.Item>
                <Form.Item label='金币' name='gold'>
                    <Input></Input>
                </Form.Item>
            </Form>
        </Drawer>
    )
}