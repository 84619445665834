import { Modal,Form, Input, message } from "antd";

import { Ajax } from '@/api/http.js';

export default function CopyToUser(props){
    const [form] = Form.useForm();

    function onFinish(vals){
        Ajax('/page/copy','post',{'ids':props.ids,'userName':vals['userName']}).then(res=>{
            message.success('复制成功！');
            props.onClose();
        })
    }

    return (
        <>
            <Modal 
                title='复制给其他用户' 
                open={props.open}
                onOk={()=>form.submit()}
                onCancel={props.onClose}
            >
                <Form form={form} onFinish={onFinish}>
                    <Form.Item label='用户名' name='userName' rules={[{required:true}]}>
                        <Input></Input>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}