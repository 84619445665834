import { useEffect, useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Layout, Row, Col, Menu } from "antd";
import { UserOutlined, HomeOutlined, VideoCameraOutlined, SettingOutlined, 
    ChromeOutlined, BranchesOutlined, HourglassOutlined } from "@ant-design/icons";

import { GlobalContext } from "@/App";
import ChangePwd from '@/pages/changepwd.js';

export default function Base(){
    const nav = useNavigate();
    const {globalData,setGlobalData} = useContext(GlobalContext);
    const [changepwdVisible,setChangepwdVisible] = useState(false);

    useEffect(()=>{
        if(!globalData.loginUser.token){
            nav('/login');
        }
    },[])

    function onLogout(){
        localStorage.clear();
        setGlobalData({});
        nav('/login');
    }

    function onOutSite(){
        localStorage.removeItem('nowSiteId');
        localStorage.removeItem('nowSiteApiUrl');
        localStorage.removeItem('nowSiteSecretKey');
        localStorage.removeItem('nowSiteName');
        let tmpData={...globalData};
        tmpData['nowSite']={};
        setGlobalData(tmpData);
    }

    return (
        <>
            <Layout>
                <Layout.Header>
                    <Row>
                        <Col span={12}>
                            <div style={{textAlign:'left',color:'#fff',fontSize:'22px',marginLeft:'-30px'}}>
                                森林CMS
                            </div>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col flex='auto'></Col>
                                {globalData.nowSite && globalData.nowSite.id &&
                                    <Col flex='300px'>
                                        <Menu mode="horizontal" theme="dark" selectable={false}>
                                            <Menu.SubMenu title={globalData.nowSite.name} icon={<ChromeOutlined />} key='topsite'>
                                                <Menu.Item key='topsite1' onClick={onOutSite}>退出此网站</Menu.Item>
                                            </Menu.SubMenu>
                                        </Menu>
                                    </Col>
                                }
                                <Col flex='200px'>
                                    <Menu mode="horizontal" theme="dark" selectable={false}>
                                        <Menu.SubMenu title={globalData.loginUser.name} icon={<UserOutlined />} key='topmenu'>
                                            <Menu.Item key='topmenu1' onClick={()=>setChangepwdVisible(true)}>修改密码</Menu.Item>
                                            <Menu.Item key='topmenu2' onClick={onLogout}>退出登录</Menu.Item>
                                        </Menu.SubMenu>
                                    </Menu>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Layout.Header>
                <Layout>
                    <Layout.Sider theme='light'>
                        <Menu theme="light" mode='inline' defaultOpenKeys={['st']}>
                            <Menu.Item icon={<HomeOutlined />} key='index' onClick={()=>nav('/')}>首页</Menu.Item>

                            <Menu.Item icon={<VideoCameraOutlined />} key='pages' onClick={()=>nav('/pages')}>我的资源</Menu.Item>
                            <Menu.Item icon={<ChromeOutlined />} key='sites' onClick={()=>nav('/sites')}>我的网站</Menu.Item>
                            <Menu.Item icon={<HourglassOutlined />} key='releases' onClick={()=>nav('/releases')}>发布记录</Menu.Item>

                            {globalData.loginUser.category==0 && 
                                <Menu.SubMenu icon={<SettingOutlined />} key='sys' title='系统管理'>
                                    <Menu.Item key='sys1' onClick={()=>nav('/users')}>用户管理</Menu.Item>
                                    <Menu.Item key='sys2' onClick={()=>nav('/sysconfig')}>系统设置</Menu.Item>
                                </Menu.SubMenu>
                            }
  
                            {globalData.nowSite && globalData.nowSite.id &&
                                <Menu.SubMenu icon={<BranchesOutlined />} key='st' title='当前网站'>
                                    <Menu.Item key='st1' onClick={()=>nav('/site/report')}>网站统计</Menu.Item>

                                    <Menu.Item key='st2' onClick={()=>nav('/site/pages')}>资源管理</Menu.Item>

                                    <Menu.Item key='st3' onClick={()=>nav('/site/users')}>网站会员</Menu.Item>
                                    <Menu.Item key='st4' onClick={()=>nav('/site/recharges')}>充值记录</Menu.Item>
                                    <Menu.Item key='st5' onClick={()=>nav('/site/buys')}>购买记录</Menu.Item>
                                    <Menu.Item key='st6' onClick={()=>nav('/site/comments')}>评论记录</Menu.Item>
                                    <Menu.Item key='st7' onClick={()=>nav('/site/likes')}>点赞记录</Menu.Item>
                                    <Menu.Item key='st9' onClick={()=>nav('/site/searchs')}>搜索词</Menu.Item>

                                    <Menu.Item key='st10' onClick={()=>nav('/site/config')}>网站设置</Menu.Item>
                                    <Menu.Item key='st11' onClick={()=>nav('/site/payments')}>收款账户</Menu.Item>
                                </Menu.SubMenu>
                            }
                        </Menu>
                    </Layout.Sider>
                    <Layout.Content style={{minHeight:'99vh',padding:'20px 20px'}}>
                        <Outlet />
                    </Layout.Content>
                </Layout>
            </Layout>
            <ChangePwd 
                visible={changepwdVisible}
                onClose={()=>setChangepwdVisible(false)}
            />
        </>
    )
}