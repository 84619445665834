import { useEffect } from "react";
import { Breadcrumb, Button, Form, Input, message, Row, Col, Typography, Select } from "antd";

import { Ajax } from '@/api/http.js';

export default function SysConfig(){
    const [form]=Form.useForm();

    useEffect(()=>{
        Ajax('/sysconfig/get','get').then(res=>{
            form.setFieldsValue({
                'imgMaxWidth':res['data']['imgMaxWidth'],
                'imgMaxSize':res['data']['imgMaxSize'],
                'imgThumbWidth':res['data']['imgThumbWidth'],

                'videoKbps':res['data']['videoKbps'],
                'videoThumbPosRate':res['data']['videoThumbPosRate'],
                'videoShortSecond':res['data']['videoShortSecond']
            })
        })
    },[])

    //提交表单
    function onFinish(vals){
        Ajax('/sysconfig/edit','post',vals).then(res=>{
            message.success('保存成功');
        })
    }

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                <Breadcrumb.Item>系统设置</Breadcrumb.Item>
            </Breadcrumb>
            <Row style={{width:'900px',margin:'10px 0 0 0'}}>
                <Col flex='auto'><Typography.Title level={3}>系统设置</Typography.Title></Col>
                <Col flex='80px'><Button type="primary" style={{width:'80px',margin:'25px 0 0 0'}} onClick={()=>form.submit()}>保存</Button></Col>
            </Row>
            <Form
                form={form}
                style={{width:'900px',margin:'0',padding:'50px 40px',backgroundColor:'#fff'}}
                onFinish={onFinish}
                labelCol={{span:4}}
            >
                <Form.Item label='图片最大宽度' name='imgMaxWidth' rules={[{required:true}]}>
                    <Input placeholder="上传图片的最大宽度" />
                </Form.Item>
                <Form.Item label='图片最大尺寸' name='imgMaxSize' rules={[{required:true}]}>
                    <Input placeholder="上传图片的最大文件大小(kb)" />
                </Form.Item>
                <Form.Item label='缩略图宽度' name='imgThumbWidth' rules={[{required:true}]}>
                    <Input placeholder="缩略图的宽度" />
                </Form.Item>
                <Form.Item label='视频码率' name='videoKbps' rules={[{required:true}]}>
                    <Select 
                        placeholder='视频码率'
                        options={[
                            {label:'768k',value:'768k'},
                            {label:'1024k',value:'1024k'}
                        ]}
                    />
                </Form.Item>
                <Form.Item label='视频封面位置' name='videoThumbPosRate' rules={[{required:true}]}>
                    <Input placeholder="百分比，填数字即可"></Input>
                </Form.Item>
                <Form.Item label='视频预览秒数' name='videoShortSecond' rules={[{required:true}]}>
                    <Input placeholder="截取到第几秒作为预览片段"></Input>
                </Form.Item>
            </Form>
        </>
    )
}