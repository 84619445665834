import { useEffect, useState } from "react";
import { Breadcrumb, Button, Form, Input, Table, DatePicker, Tag, Select} from "antd";

import { Ajax } from '@/api/http.js';

export default function Releases(){
    const [sites,setSites] = useState([]);

    const [rangeDate,setRangeDate] = useState(null);
    const [createrName,setCreaterName] = useState(null);
    const [siteId,setSiteId] = useState(null);    

    const [tableData,setTableData]=useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const [tablePageCurrent,setTablePageCurrent] = useState(1);
    const [tablePageSize,setTablePageSize] = useState(20);
    const [tableTotal,setTableTotal] = useState(0);
    const [tableSelectedRowKeys, setTableSelectedRowKeys] = useState([]);

    useEffect(()=>{
        Ajax('/site/getlist','get').then(res=>{
            let tmpData=[];
            res['data'].map(one=>{
                tmpData.push({
                    'label':one.name,
                    'value':one.id
                })
            })
            setSites(tmpData);
        })

        getTableData(tablePageCurrent,tablePageSize);
    },[])

    //实际获取表格数据
    function getTableData(pageCurrent,pageSize){
        setTableLoading(true);
        setTableSelectedRowKeys([]);

        let startDate=null;
        let endDate=null;
        if(rangeDate){
            startDate=rangeDate[0].format('YYYY-MM-DD');
            endDate=rangeDate[1].format('YYYY-MM-DD');
        }

        Ajax('/release/getlist','get',{
            startDate:startDate,
            endDate:endDate,
            createrName:createrName,
            siteId:siteId,
            pageCurrent:pageCurrent,
            pageSize:pageSize
        },false).then(res=>{
            setTablePageCurrent(pageCurrent);
            setTablePageSize(pageSize);
            setTableData(res['data']);
            setTableTotal(res['total']);
            setTableLoading(false);
        }).catch(err=>{
            setTableLoading(false);
        })
    }

    //点击搜索按钮
    function onSearch(){
        getTableData(1,tablePageSize);
    }

    //点击分页或排序
    function onChangeTablePag(pagination, filters, sorter){
        getTableData(pagination['current'],pagination['pageSize']);
    }
   
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>发布记录</Breadcrumb.Item>
            </Breadcrumb>
            <Form
                layout="inline"
                style={{margin:'20px 0 20px 0'}}
            >
                <Form.Item>
                    <DatePicker.RangePicker value={rangeDate} onChange={(v)=>setRangeDate(v)} />
                </Form.Item>
                <Form.Item>
                    <Input placeholder="创建人名称" value={createrName} onChange={(e)=>setCreaterName(e.target.value)}></Input>
                </Form.Item>
                <Form.Item>
                    <Select options={sites} value={siteId} onChange={(v)=>setSiteId(v)} placeholder='网站' style={{width:'300px'}} allowClear></Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                </Form.Item>
            </Form>
            <Table
                columns={[
                    {
                        title:'id',
                        dataIndex:'id',
                        key:'id'
                    },
                    {
                        title:'网站名称',
                        dataIndex:'siteName',
                        key:'siteName'
                    },
                    {
                        title:'资源标题',
                        dataIndex:'pageTitle',
                        key:'pageTitle'
                    },
                    {
                        title:'金币',
                        dataIndex:'gold',
                        key:'gold'
                    },
                    {
                        title:'状态',
                        dataIndex:'isEnable',
                        key:'isEnable',
                        render:(_,record)=>{
                            switch(record.isEnable){
                                case false:
                                    return <Tag color="#f50">失败</Tag>
                                case true:
                                    return <Tag color="#87d068">成功</Tag>
                            }
                        }
                    },
                    {
                        title:'返回信息',
                        dataIndex:'msg',
                        key:'msg'
                    },
                    {
                        title:'创建人',
                        dataIndex:'createrName',
                        key:'createrName'
                    },
                    {
                        title:'创建时间',
                        dataIndex:'createdTime',
                        key:'createdTime'
                    }
                ]}
                rowKey='id'
                dataSource={tableData}
                pagination={{
                    current:tablePageCurrent,
                    pageSize:tablePageSize,
                    total:tableTotal,
                    showTotal:(t)=>'合计'+t
                }}
                onChange={onChangeTablePag}
                loading={tableLoading}
            />
        </>
    )
}
