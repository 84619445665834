import { useState, useEffect } from "react";
import { Button, Form, Input, Drawer, Upload, Select} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { Ajax,baseUrl } from '@/api/http.js';

export default function Page(props){
    const [form] = Form.useForm();
    const [title,setTitle] = useState('');

    useEffect(()=>{
        if(props.id){
            Ajax('/page/get','get',{'id':props.id}).then(res=>{
                setTitle(res['data']['title']);
                form.setFieldsValue({
                    'category':res['data']['category'],
                    'title':res['data']['title'],
                    'content':res['data']['content'],
                    'imgs':[],
                    'video':[]
                });
                if(res['data']['imgs']){
                    let imgs=[];
                    res['data']['imgs'].map(one=>{
                        imgs.push({
                            'url':one.source,
                            'thumbUrl':one.thumb || one.source
                        })
                    })
                    form.setFieldsValue({'imgs':imgs})
                }
                if(res['data']['video']){
                    form.setFieldsValue({
                        'video':[{
                            'url':res['data']['video']['source'],
                            'thumbUrl':res['data']['video']['thumb']
                        }]
                    })
                }
            })
        }else{
            setTitle('新的资源');
            form.resetFields();
        }
    },[props.id])

    //保存
    const onFinish=(vals)=>{
        let url='/page/add';
        if(props.id){
            url='/page/edit';
            vals['id']=props.id;
        }

        //获取图片
        let imgs=[];
        if(vals['imgs']){
            vals['imgs'].map(one=>{
                imgs.push(one.url || one.response.data.url);
            })
        }
        vals['imgs']=imgs;

        //获取视频
        let video=null;
        if(vals['video']){
            vals['video'].map(one=>{
                video=one.url || one.response.data.url;
            })
        }
        vals['video']=video;

        Ajax(url,'post',vals,true,true,true).then(res=>{
            if(!props.id){
                form.resetFields();
            }
            props.onClose();
            props.onRefresh();
        })
    }

    function onUploadVideo(file,fileList){
        let vtitle=form.getFieldValue('title');
        if(!vtitle){
            form.setFieldValue('title',file.name.replace('.mp4',''));
        }
        let vcategory=form.getFieldValue('category');
        if(vcategory==null){
            form.setFieldValue('category',1);
        }
        return true;
    }

    return (
        <Drawer
            width={800}
            open={props.visible}
            onClose={props.onClose}
            destroyOnClose={true}
            title={title}
            extra={
                <Button type="primary" onClick={()=>form.submit()}>保存</Button>
            }
        >
            <Form form={form} onFinish={onFinish} labelCol={{span:2,offset:0}}>
                <Form.Item label='类型' name='category' rules={[{required:true}]}>
                    <Select
                        options={[
                            {label:'帖子',value:0},
                            {label:'短视频',value:1}
                        ]}
                    />
                </Form.Item>
                <Form.Item label='标题' name='title' rules={[{required:true}]}>
                    <Input></Input>
                </Form.Item>
                <Form.Item label='正文' name='content'>
                    <Input.TextArea rows={10}></Input.TextArea>
                </Form.Item>
                <Form.Item label='图片' name='imgs' valuePropName="fileList" getValueFromEvent={e=>e && e.fileList}>
                    <Upload
                        listType='picture-card'
                        multiple={true}
                        maxCount={9}
                        accept="image/*"
                        action={baseUrl+'/upload/img'}
                        headers={{'Authorization':localStorage.getItem('loginUserToken')}}
                        disabled={props.id}
                    >
                        <div>
                            <PlusOutlined />
                            <div style={{marginTop: 8}}>选择文件</div>
                        </div>
                    </Upload>
                </Form.Item>
                <Form.Item label='视频' name='video' valuePropName="fileList" getValueFromEvent={e=>e && e.fileList}>
                    <Upload
                        listType='picture-card'
                        multiple={false}
                        maxCount={1}
                        accept="video/*"
                        action={baseUrl+'/upload/video'}
                        headers={{'Authorization':localStorage.getItem('loginUserToken')}}
                        disabled={props.id}
                        beforeUpload={onUploadVideo}
                    >
                        <div>
                            <PlusOutlined />
                            <div style={{marginTop: 8}}>选择文件</div>
                        </div>
                    </Upload>
                </Form.Item>
            </Form>
        </Drawer>
    )
}