import { useEffect, useState } from "react";
import { Button, Form, Input, Select, Drawer} from "antd";

import { Ajax } from '@/api/http.js';

export default function User(props){
    const [form] = Form.useForm();
    const [title,setTitle] = useState('');

    useEffect(()=>{
        if(props.id){
            Ajax('/user/get','get',{'id':props.id}).then(res=>{
                setTitle(res['data']['name']);
                form.setFieldsValue({
                    'category':res['data']['category'],
                    'name':res['data']['name'],
                    'password':''
                })
            })
        }else{
            setTitle('新的用户');
            form.resetFields();
        }
    },[props.id])

    //保存
    const onFinish=(vals)=>{
        let url='/user/add';
        if(props.id){
            url='/user/edit';
            vals['id']=props.id;
        }

        Ajax(url,'post',vals,true,true,true).then(res=>{
            if(!props.id){
                form.resetFields();
            }
            props.onClose();
            props.onRefresh();
        })
    }

    return (
        <Drawer
            width={500}
            visible={props.visible}
            onClose={props.onClose}
            title={title}
            extra={
                <Button type="primary" onClick={()=>form.submit()}>保存</Button>
            }
        >
            <Form form={form} onFinish={onFinish} labelCol={{span:3}}>
                <Form.Item label='类别' name='category' rules={[{required:true}]}>
                    <Select placeholder='用户类别'>
                        <Select.Option value={0}>管理员</Select.Option>
                        <Select.Option value={1}>会员</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='名称' name='name' rules={[{required:true}]}>
                    <Input></Input>
                </Form.Item>
                <Form.Item label='密码' name='password'>
                    <Input.Password placeholder="新用户必须输入，老用户可不输入"></Input.Password>
                </Form.Item>
            </Form>
        </Drawer>
    )
}