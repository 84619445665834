import { useEffect, useState } from "react";

import { Breadcrumb, Button, Form, Input, Select, Table, Space, Popconfirm, DatePicker} from "antd";
import { PlusOutlined,DeleteOutlined } from "@ant-design/icons";

import { SiteAjax } from '@/api/http.js';

import SiteAbleSwitch from "@/components/site/ableswitch";
import SiteUser from "@/components/site/user";


export default function SiteUsers(){
    const [rangeDate,setRangeDate] = useState(null);
    const [name,setName] = useState(null);
    const [isEnable,setIsEnable] = useState(null);

    const [tableData,setTableData]=useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const [tablePageCurrent,setTablePageCurrent] = useState(1);
    const [tablePageSize,setTablePageSize] = useState(20);
    const [tableTotal,setTableTotal] = useState(0);
    const [tableSelectedRowKeys, setTableSelectedRowKeys] = useState([]);

    const [drawerVisible,setDrawerVisible] = useState(false);
    const [drawerObjId,setDrawerObjId] = useState(null);

    useEffect(()=>{
        getTableData(tablePageCurrent,tablePageSize);
    },[])

    //实际获取表格数据
    function getTableData(pageCurrent,pageSize){
        setTableLoading(true);
        setTableSelectedRowKeys([]);

        let startDate=null;
        let endDate=null;
        if(rangeDate){
            startDate=rangeDate[0].format('YYYY-MM-DD');
            endDate=rangeDate[1].format('YYYY-MM-DD');
        }

        SiteAjax('/admin/user/getlist','get',{
            startDate:startDate,
            endDate:endDate,
            name:name,
            isEnable:isEnable,
            pageCurrent:pageCurrent,
            pageSize:pageSize
        },false).then(res=>{
            setTablePageCurrent(pageCurrent);
            setTablePageSize(pageSize);
            setTableData(res['data']);
            setTableTotal(res['total']);
            setTableLoading(false);
        }).catch(err=>{
            setTableLoading(false);
        })
    }

    //点击搜索按钮
    function onSearch(){
        getTableData(1,tablePageSize);
    }

    //点击分页或排序
    function onChangeTablePag(pagination, filters, sorter){
        getTableData(pagination['current'],pagination['pageSize']);
    }

    //新建
    function onAdd(){
        setDrawerVisible(true);
        setDrawerObjId(null);
    }

    //修改
    function onEdit(oId){
        setDrawerVisible(true);
        setDrawerObjId(oId);
    }

    //删除
    function onDels(){
        SiteAjax('/admin/user/dels','post',{'ids':tableSelectedRowKeys},true,true,true).then(res=>{
            getTableData(tablePageCurrent,tablePageSize);
        })
    }
   
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>当前网站</Breadcrumb.Item>
                <Breadcrumb.Item>网站会员</Breadcrumb.Item>
            </Breadcrumb>
            <Form
                layout="inline"
                style={{margin:'20px 0 20px 0'}}
            >
                <Form.Item>
                    <DatePicker.RangePicker value={rangeDate} onChange={(v)=>setRangeDate(v)} />
                </Form.Item>
                <Form.Item>
                    <Input placeholder="名称" value={name} onChange={(e)=>setName(e.target.value)}></Input>
                </Form.Item>
                <Form.Item>
                    <Select placeholder='状态' value={isEnable} onChange={(val)=>setIsEnable(val)} style={{width:'100px'}} allowClear>
                        <Select.Option value={1}>正常</Select.Option>
                        <Select.Option value={0}>禁用</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                </Form.Item>
            </Form>
            <Space style={{margin:'0 0 10px 0'}}>
                <Button type="primary" onClick={onAdd}><PlusOutlined />新建</Button>
                <Popconfirm
                        title='确定要删除吗？'
                        onConfirm={onDels}
                    >
                        <Button type="primary" danger disabled={tableSelectedRowKeys.length<1}><DeleteOutlined />删除</Button>
                    </Popconfirm>
            </Space>
            <Table
                rowSelection={{
                    selectedRowKeys:tableSelectedRowKeys,
                    onChange:(newKeys)=>setTableSelectedRowKeys(newKeys)
                }}
                columns={[
                    {
                        title:'id',
                        dataIndex:'id',
                        key:'id'
                    },
                    {
                        title:'名称',
                        dataIndex:'name',
                        key:'name'
                    },
                    {
                        title:'邮箱',
                        dataIndex:'email',
                        key:'email'
                    },
                    {
                        title:'已充值金额',
                        dataIndex:'money',
                        key:'money'
                    },
                    {
                        title:'可用金币',
                        dataIndex:'gold',
                        key:'gold'
                    },
                    {
                        title:'允许上传',
                        dataIndex:'allowUpload',
                        key:'allowUpload',
                        render:(_,record)=>(
                            <SiteAbleSwitch 
                                id={record.allowUpload} 
                                isChecked={record.allowUpload} 
                                checkedTxt='允许' 
                                unCheckedTxt='禁止' 
                                url='/admin/user/setallowupload'
                            />
                        )
                    },
                    {
                        title:'允许评论',
                        dataIndex:'allowComment',
                        key:'allowComment',
                        render:(_,record)=>(
                            <SiteAbleSwitch 
                                id={record.allowComment} 
                                isChecked={record.allowComment} 
                                checkedTxt='允许' 
                                unCheckedTxt='禁止' 
                                url='/admin/user/setallowcomment'
                            />
                        )
                    },
                    {
                        title:'最新登录ip',
                        dataIndex:'lastLoginIp',
                        key:'lastLoginIp'
                    },
                    {
                        title:'最新登录时间',
                        dataIndex:'lastLoginTime',
                        key:'lastLoginTime'
                    },
                    {
                        title:'状态',
                        dataIndex:'isEnable',
                        key:'isEnable',
                        render:(_,record)=>(
                            <SiteAbleSwitch 
                                id={record.id} 
                                isChecked={record.isEnable} 
                                checkedTxt='正常' 
                                unCheckedTxt='禁用' 
                                url='/admin/user/setenable'
                            />
                        )
                    },
                    {
                        title:'操作',
                        dataIndex:'action',
                        render:(_,record)=>(
                            <Space size='middle'>
                                <a onClick={()=>onEdit(record.id)}>修改</a>
                            </Space>
                        )
                    }
                ]}
                rowKey='id'
                dataSource={tableData}
                pagination={{
                    current:tablePageCurrent,
                    pageSize:tablePageSize,
                    total:tableTotal,
                    showTotal:(t)=>'合计'+t
                }}
                onChange={onChangeTablePag}
                loading={tableLoading}
            />
            <SiteUser
                visible={drawerVisible}
                onClose={()=>setDrawerVisible(false)}
                onRefresh={()=>getTableData(tablePageCurrent,tablePageSize)}
                id={drawerObjId}
            />
        </>
    )
}
