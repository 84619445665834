import { useEffect, useState } from "react";
import { Switch } from "antd";

import { Ajax } from '@/api/http.js';

//props参数:id,isChecked,checkedTxt,unCheckedTxt,url,siteId(如果有)

export default function AbleSwitch(props){
    const [isChecked,setIsChecked]=useState(true);
    const [loading,setLoading]=useState(false);

    useEffect(()=>{
        setIsChecked(props.isChecked);
    },[props.id,props.isChecked])

    function onChange(v){
        setLoading(true);
        Ajax(props.url,'post',{'id':props.id,'value':v},false).then(res=>{
            setIsChecked(v);
            setLoading(false);
        }).catch(err=>{
            setLoading(false);
        })
    }

    return (
        <Switch 
            checkedChildren={props.checkedTxt}
            unCheckedChildren={props.unCheckedTxt}
            checked={isChecked}
            loading={loading}
            onChange={onChange}
            disabled={props.disabled}
        />
    )
}