import { useEffect, useState } from "react";
import { Breadcrumb, Button, Form, Input, Select, Table, Tag, DatePicker} from "antd";

import { SiteAjax } from '@/api/http.js';

export default function SiteRecharges(){
    const [rangeDate,setRangeDate] = useState(null);
    const [createrName,setCreaterName] = useState(null);
    const [isPay,setIsPay] = useState(null);
    const [payId,setPayId] = useState(null);

    const [tableData,setTableData]=useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const [tablePageCurrent,setTablePageCurrent] = useState(1);
    const [tablePageSize,setTablePageSize] = useState(20);
    const [tableTotal,setTableTotal] = useState(0);
    const [tableSelectedRowKeys, setTableSelectedRowKeys] = useState([]);

    useEffect(()=>{
        getTableData(tablePageCurrent,tablePageSize);
    },[])

    //实际获取表格数据
    function getTableData(pageCurrent,pageSize){
        setTableLoading(true);
        setTableSelectedRowKeys([]);

        let startDate=null;
        let endDate=null;
        if(rangeDate){
            startDate=rangeDate[0].format('YYYY-MM-DD');
            endDate=rangeDate[1].format('YYYY-MM-DD');
        }

        SiteAjax('/admin/recharge/getlist','get',{
            startDate:startDate,
            endDate:endDate,
            createrName:createrName,
            isPay:isPay,
            payId:payId,
            pageCurrent:pageCurrent,
            pageSize:pageSize
        },false).then(res=>{
            setTablePageCurrent(pageCurrent);
            setTablePageSize(pageSize);
            setTableData(res['data']);
            setTableTotal(res['total']);
            setTableLoading(false);
        }).catch(err=>{
            setTableLoading(false);
        })
    }

    //点击搜索按钮
    function onSearch(){
        getTableData(1,tablePageSize);
    }

    //点击分页或排序
    function onChangeTablePag(pagination, filters, sorter){
        getTableData(pagination['current'],pagination['pageSize']);
    }
   
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>当前网站</Breadcrumb.Item>
                <Breadcrumb.Item>充值记录</Breadcrumb.Item>
            </Breadcrumb>
            <Form
                layout="inline"
                style={{margin:'20px 0 20px 0'}}
            >
                <Form.Item>
                    <DatePicker.RangePicker value={rangeDate} onChange={(v)=>setRangeDate(v)} />
                </Form.Item>
                <Form.Item>
                    <Input placeholder="用户名" value={createrName} onChange={(e)=>setCreaterName(e.target.value)}></Input>
                </Form.Item>
                <Form.Item>
                    <Input placeholder="收款平台订单号" value={payId} onChange={(e)=>setPayId(e.target.value)}></Input>
                </Form.Item>
                <Form.Item>
                    <Select placeholder='付款状态' value={isPay} onChange={(val)=>setIsPay(val)} style={{width:'100px'}} allowClear>
                        <Select.Option value={1}>已付款</Select.Option>
                        <Select.Option value={0}>未付款</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                    
                </Form.Item>
            </Form>
            <Table
                rowSelection={{
                    selectedRowKeys:tableSelectedRowKeys,
                    onChange:(newKeys)=>setTableSelectedRowKeys(newKeys)
                }}
                columns={[
                    {
                        title:'id',
                        dataIndex:'id',
                        key:'id'
                    },
                    {
                        title:'收款账户',
                        dataIndex:'paymentName',
                        key:'paymentName'
                    },
                    {
                        title:'金额',
                        dataIndex:'money',
                        key:'money'
                    },
                    {
                        title:'金币',
                        dataIndex:'gold',
                        key:'gold'
                    },
                    {
                        title:'付款状态',
                        dataIndex:'isPay',
                        key:'isPay',
                        render:(_,record)=>{
                            switch(record.isPay){
                                case false:
                                    return <Tag color="#f50">未付款</Tag>
                                case true:
                                    return <Tag color="#2db7f5">已付款</Tag>
                            }
                        }
                    },
                    {
                        title:'收款平台订单号',
                        dataIndex:'payId',
                        key:'payId'
                    },
                    {
                        title:'充值人',
                        dataIndex:'createrName',
                        key:'createrName'
                    },
                    {
                        title:'充值时间',
                        dataIndex:'createdTime',
                        key:'createdTime'
                    }
                ]}
                rowKey='id'
                dataSource={tableData}
                pagination={{
                    current:tablePageCurrent,
                    pageSize:tablePageSize,
                    total:tableTotal,
                    showTotal:(t)=>'合计'+t
                }}
                onChange={onChangeTablePag}
                loading={tableLoading}
            />
        </>
    )
}
